import React from "react";
import { Container } from "react-bootstrap";
import Menu from "./home_componants/Menu";
import Playlist from "./home_componants/Playlist";
import Slider from "./home_componants/Slider";
import classes from "./Home.module.css";
import { connect } from "react-redux";
import Authenticate from "./home_componants/Authenticate";

function Home(props) {
  return (
    <Container className={classes.body} fluid>
      {props.userData &&
      props.userData.isUserAuthenticated &&
      props.userData.isUserAuthenticated.isgood == true ? (
        <div>
          <Menu />
          <Slider
            className="mt-3"
            slideData={
              props.imagelistData &&
              props.imagelistData.imagelist &&
              props.imagelistData.imagelist
            }
          />
          <Playlist />
        </div>
      ) : (
        <Authenticate />
      )}
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    imagelistData: state.ImageListReducer,
    userData: state.UserReducer,
  };
};

export default connect(mapStateToProps)(Home);
/**/
