import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Container, Form,Image } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { connect } from "react-redux";
import classes from "./Slider.module.css";

function Slider(props) {
  const autoPlayRef = useRef();
  const [Checked, setChecked] = useState(true);

  const [current, setCurrent] = useState(0);
  const length = props.slideData && props.slideData.length;
  const timeoutRef = React.useRef(null);

  useEffect(() => {
  
    timeoutRef.current=setTimeout(nextSlide, 3000);
    return () => {
      resetTimeout()
    };
  }, [current]);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const nextSlide = () => {
    //console.log(current);
    setCurrent(current >= length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const setupMedia = (index, slide) => {
    if (index == current - 2) {
      return (
        <div className={classes.slide_pprev} key={index}>
          {getMedia(slide)}
        </div>
      );
    } else if (index == current - 1) {
      return (
        <div className={classes.slide_prev} key={index}>
          {getMedia(slide)}
        </div>
      );
    } else if (index == current) {
      return (
        <div className={classes.slide_active} key={index}>
         <Image src={slide.fileurl} alt="travel image" className={classes.image_active} rounded/>
        </div>
      );
    } else if (index == current + 1) {
      return (
        <div className={classes.slide_next} key={index}>
          {getMedia(slide)}
        </div>
      );
    } else if (index == current + 2) {
      return (
        <div className={classes.slide_nnext} key={index}>
          {getMedia(slide)}
        </div>
      );
    }
  };

  const getMedia = (slide) => {
    return (
      <Image src={slide.fileurl} alt="travel image" className={classes.image} rounded/>
    );
  };

  const autoPlay = async () => {
    setInterval(function () {
      nextSlide();
      console.log(current);
    }, 1000);
  };

  return (
    <div>
   
      <section className={classes.slider}>
        <MdKeyboardArrowLeft
          className={classes.left_arrow}
          onClick={prevSlide}
        />
        <MdKeyboardArrowRight
          className={classes.right_arrow}
          onClick={nextSlide}
        />
        {props.slideData &&
          props.slideData.map((slide, index) => {
            return setupMedia(index, slide);
          })}
      </section>
    </div>
  );
}

export default Slider;
/*
useEffect(() => {
    autoPlayRef.current = nextSlide;
  });

  useEffect(() => {
    console.log("resetting now");
    setCurrent(0);
    autoPlayRef.current=null;
    const play = () => {
      autoPlayRef.current();
    };
    const interval = setInterval(play, 3000);
  }, []);
 */
