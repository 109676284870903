import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Button,
  Image,
  ListGroup,
  Overlay,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import {
  BsPlayCircle,
  BsPauseCircle,
  BsSkipEndCircle,
  BsPlusCircle,
  BsFillStopCircleFill,
} from "react-icons/bs";
import classes from "./Playlist.module.css";
import Player from "react-material-music-player"; // default export

import { Track, PlayerInterface } from "react-material-music-player";
import { getAllPlaylist } from "../../redux/playlist/playlistAction";
import { connect } from "react-redux";

function Playlist(props) {
  useEffect(() => {
    props.getplaylist();
    //createplay(0);
  }, []);

  const TEST_MEDIA =
    "https://raw.githubusercontent.com/the-maazu/react-material-music-player/master/sample_media/";

  const [curAud_id, setcurAud_id] = useState(0);
  const Songlist = [];

  let Playlist_toplay = [];
  const createplay = (id) => {
    console.log("Start from=" + id);
    setcurAud_id(id);
    Playlist_toplay = [];
    for (let i = id; i < Songlist.length; i++) {
      Playlist_toplay.push(
        new Track(
          Songlist[i].id,
          Songlist[i].cover,
          Songlist[i].song,
          Songlist[i].artist,
          Songlist[i].src
        )
      );
    }
    console.log(Playlist_toplay);
    PlayerInterface.play(Playlist_toplay);
  };

  const singleplay = (id) => {
    console.log("Playing=" + id);
    if (id == -1) {
      PlayerInterface.play([new Track("", "", "", "", "")]);
    } else {
      PlayerInterface.play([
        new Track(
          Songlist[id].id,
          Songlist[id].cover,
          Songlist[id].song,
          Songlist[id].artist,
          Songlist[id].src
        ),
      ]);
    }
  };

  const addtoplay_list = (id) => {
    PlayerInterface.playLater(
      new Track(
        Songlist[id].id,
        Songlist[id].cover,
        Songlist[id].song,
        Songlist[id].artist,
        Songlist[id].src
      )
    );
  };

  const setupPlayList = () => {
    if (props.playlistData.musiclist.length != 0) {
      props.playlistData.musiclist.map((audio, idx) => {
        Songlist.push({
          id: idx,
          cover: audio.coverurl,
          song: audio.song,
          artist: audio.artist,
          src: audio.fileurl,
        });
      });

      Songlist.push({
        id: "NAN ",
        cover: TEST_MEDIA + "bach.jpg",
        song: "68 Choral in heart of ashley",
        artist: "Bach",
        src: TEST_MEDIA + "Bach%20--%20BWV%20245%20--%2068%20Choral.mp3",
      });

      return (
        <Table
          borderless
          variant="dark"
          className={classes.tablebody}
          hover
          responsive
        >
          <thead>
            <tr>
              <th className="d-none d-md-table-cell">Srno</th>
              <th>Cover</th>
              <th>Song</th>
              <th className="d-none d-md-table-cell">Artist</th>
              <th>
                <div className="d-flex align-items-end">
                  <p className="me-2">Controls</p>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tltip1">Stop</Tooltip>}
                  >
                    <div className={classes.btncontrols}>
                      <BsFillStopCircleFill
                        onClick={(e) => {
                          singleplay(-1);
                        }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {Songlist.map((song, idx) => {
              return (
                <tr key={idx}>
                  <td className="d-none d-md-table-cell">{song.id}</td>
                  <td
                    onClick={() => {
                      singleplay(idx);
                    }}
                  >
                    <Image src={song.cover} width="70" />
                  </td>
                  <td
                    onClick={() => {
                      singleplay(idx);
                    }}
                  >
                    {song.song}
                  </td>
                  <td
                    className="d-none d-md-table-cell"
                    onClick={() => {
                      singleplay(idx);
                    }}
                  >
                    {song.artist}
                  </td>
                  <td>
                    <div className="d-flex ">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tltip1">Reset</Tooltip>}
                      >
                        <div className={`me-2 ${classes.btncontrols}`}>
                          <BsSkipEndCircle
                            onClick={(e) => {
                              e.preventDefault();
                              createplay(idx);
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tltip2">Add to playlist</Tooltip>}
                      >
                        <div className={classes.btncontrols}>
                          <BsPlusCircle
                            onClick={(e) => {
                              e.preventDefault();
                              addtoplay_list(idx);
                            }}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      );
    } else return <h3>No Playlist added</h3>;
  };
  return (
    <div className={classes.playlistbody}>
      <h3 className={`text-center ${classes.title}`}>Playlist</h3>
      <Container fluid className={classes.listview}>
        {setupPlayList()}
      </Container>
      <Player disableDrawer={false} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    playlistData: state.PlayListReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getplaylist: () => dispatch(getAllPlaylist()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
/**
 *   <div className={classes.player}>
        <div className={classes.playinfo} >
          <Image
            className={classes.img}
            src="https://images.unsplash.com/photo-1648347499701-8fb77cb36297?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          ></Image>
          <div className={classes.info}>
            <strong>Song name</strong>
            <p >Artist name</p>
          </div>
        </div>
      </div>
 */
