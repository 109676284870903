import { AUTH_ERROR, AUTH_IN_USER, USER_AUTHENTICATION } from "./userTypes";

const UserDetails = {
  loading: false,
  isUserAuthenticated: {},
  error: "",
};

const userReducer = (state = UserDetails, action) => {
  switch (action.type) {
    case AUTH_IN_USER:
      return {
        ...state,
        loading: true,
      };
    case USER_AUTHENTICATION:
      return {
        ...state,
        loading: false,
        isUserAuthenticated: action.payload,
      };

    case AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
