import { combineReducers } from "redux";
import playlistReducer from "./playlist/playlistReducer";
import imagelistReducer from "./imagelist/imagelistReducer";
import userReducer from "./users/userReducer";

const rootReducer = combineReducers({
  PlayListReducer: playlistReducer,
  ImageListReducer: imagelistReducer,
  UserReducer: userReducer,
});

export default rootReducer;
