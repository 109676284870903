import logo from "./logo.svg";
import "./App.css";
import { Button } from "react-bootstrap";
import Home from "./pages/Home";
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import TawkTo from 'tawkto-react'

function App() {
  useEffect(() => {
    
    var tawk = new TawkTo('624811ec2abe5b455fc36e37', '1fvkoc19b')

    tawk.onStatusChange((status) => 
    {
         console.log(status)
    })

}, [])
  return (
    <Switch>
      <Route path="/" exact>
        <Home />
      </Route>
    </Switch>
  );
}

export default App;
/**
 *
 */
