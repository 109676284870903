import axios from "axios";
import {
  GET_ALL_IMAGELIST,
  LOADING_IMAGELIST,
  LOADING_IMAGELIST_ERROR,
} from "./imagelistTypes";

export const get_all_imagelist = (payload) => {
  return {
    type: GET_ALL_IMAGELIST,
    payload: payload,
  };
};

export const get_imagelist_error = (error) => {
  return {
    type: LOADING_IMAGELIST_ERROR,
    payload: error,
  };
};
export const loading_action = () => {
  return {
    type: LOADING_IMAGELIST,
  };
};

export const getAllImagelist = (folder) => {
  return (dispatch) => {
    dispatch(loading_action());
    axios
      .get(process.env.REACT_APP_OLI_SERVER_URL + `api/getimages/${folder}`)
      .then((res) => {
        //console.log("Explore tokens..");
        //console.log(res.data);
        const data = res.data;
        dispatch(get_all_imagelist(data));
      })
      .catch((err) => {
        //console.log("Tokens of explore error..");
        //console.log(err);
        dispatch(get_imagelist_error(err));
      });
  };
};
