import React, { useState } from "react";
import classes from "./Authenticate.module.css";
import {
  Form,
  Container,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import axios from "axios";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function ResetPass(props) {
  const [Username, setUsername] = useState(undefined);
  const [Email, setEmail] = useState(undefined);
  const [Vcode, setVcode] = useState(undefined);

  const [isVSent, setisVSent] = useState(false);
  const [Timer, setTimer] = useState(0);
  const [isCodeVerified, setisCodeVerified] = useState(false);
  const [Stat, setStat] = useState(undefined);

  const [Password, setPassword] = useState(undefined);
  const [RPassword, setRPassword] = useState(undefined);
  const [showPass, setshowPass] = useState(false);

  const [loading, setloading] = useState(false);

  const sendvc = async () => {
    setloading(true);
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/sendvcode", {
        username: Username,
        email: Email,
      })
      .then(async (response) => {
        console.log(response.data);
        setisVSent(true);
        setloading(false);
        let i = 30;
        while (i >= 0) {
          setTimer(i);
          i--;
          await new Promise((r) => setTimeout(r, 1000));
        }
     
      })
      .catch((err) => {
        console.log("Email Error:" + err);
        setloading(false);
      });
  };

  const verifycode = () => {
    setloading(true);
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/verifycode", {
        username: Username,
        vcode: Vcode,
      })
      .then((response) => {
        console.log(response.data);
        if (response.data == "1") {
          setisCodeVerified(true);
          setPassword("");
          setRPassword("");
        } else {
          setStat("Wrong verification code !");
        }
        setloading(false);
      })
      .catch((err) => {
        console.log("Verification Error:" + err);
        setloading(false);
      });
  };
  const resetpass = () => {
    setloading(true);
    const data = {
      username: Username,
      password: Password,
    };
    axios
      .post(process.env.REACT_APP_OLI_SERVER_URL + "api/resetpass", data)
      .then((response) => {
        console.log(response.data);
        setloading(false);
        props.loginback();
      })
      .catch((err) => {
        setloading(false);
        console.log("Submit aud Error:" + err);
      });
  };

  return (
    <div>
      <h2 className="text-center">Reset Password</h2>
      {isCodeVerified != true ? (
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter username"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Group>
          {isVSent != true ? (
            <div className="text-center">
              <Button
                className={classes.btnlogin}
                variant="primary"
                onClick={sendvc}
              >
                Send Verification code
              </Button>
            </div>
          ) : (
            <div>
              <Form>
                <Form.Group className="mb-3" controlId="code">
                  <Form.Label>Enter Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter verification code"
                    onChange={(e) => {
                      setVcode(e.target.value);
                    }}
                  />
                </Form.Group>
                <div className="text-center">
                  <Button
                    className={classes.btnlogin}
                    variant="primary"
                    onClick={verifycode}
                  >
                    Verify
                  </Button>
                  {Timer == 0 ? (
                    <p className={classes.resetp} onClick={sendvc}>
                      Resend Code
                    </p>
                  ) : (
                    <p className={classes.resetp}>wait for {Timer} seconds</p>
                  )}
                  <p className="danger">{Stat}</p>
                </div>
              </Form>
            </div>
          )}
        </Form>
      ) : (
        <Form>
          <InputGroup className="mb-3">
            <FormControl
              id="password"
              type={showPass == true ? "text" : "password"}
              placeholder="Enter password"
              value={Password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button
              variant="primary"
              onClick={() => {
                setshowPass(!showPass);
              }}
            >
              {showPass == true ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
            </Button>
          </InputGroup>

          <InputGroup className="mb-3">
            <FormControl
              id="rpassword"
              type={showPass == true ? "text" : "password"}
              placeholder="Re-Enter password"
              value={RPassword}
              onChange={(e) => {
                setRPassword(e.target.value);
              }}
            />
            <Button
              variant="primary"
              onClick={() => {
                setshowPass(!showPass);
              }}
            >
              {showPass == true ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
            </Button>
          </InputGroup>
          <div className="text-center">
            <Button
              className={classes.btnlogin}
              variant="primary"
              onClick={resetpass}
            >
              Reset My Password
            </Button>
          </div>
        </Form>
      )}

      {loading == true ? (
        <div className="text-center">
        <Spinner
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        /></div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ResetPass;
