import {
  GET_ALL_PLAYLIST,
  LOADING_PLAYLIST,
  LOADING_PLAYLIST_ERROR,
} from "./playlistTypes";

const PlayList = {
  loading: false,
  musiclist: [],
  error: "",
};

const playlistReducer = (state = PlayList, action) => {
  switch (action.type) {
    case LOADING_PLAYLIST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_PLAYLIST:
      return {
        ...state,
        loading: false,
        musiclist: action.payload,
      };

    case LOADING_PLAYLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default playlistReducer;
