import axios from "axios";
import {
  GET_ALL_PLAYLIST,
  LOADING_PLAYLIST,
  LOADING_PLAYLIST_ERROR,
} from "./playlistTypes";

export const get_all_playlist = (payload) => {
  return {
    type: GET_ALL_PLAYLIST,
    payload: payload,
  };
};

export const get_playlist_error = (error) => {
  return {
    type: LOADING_PLAYLIST_ERROR,
    payload: error,
  };
};
export const loading_action = () => {
  return {
    type: LOADING_PLAYLIST,
  };
};

export const getAllPlaylist = () => {
  return (dispatch) => {
    dispatch(loading_action());
    axios
      .get(process.env.REACT_APP_OLI_SERVER_URL + "api/getallplaylist")
      .then((res) => {
        //console.log("Explore tokens..");
        //console.log(res.data);
        const data = res.data;
        dispatch(get_all_playlist(data));
      })
      .catch((err) => {
        //console.log("Tokens of explore error..");
        //console.log(err);
        dispatch(get_playlist_error(err));
      });
  };
};
