import axios from "axios";
import { AUTH_ERROR, AUTH_IN_USER, USER_AUTHENTICATION } from "./userTypes";

export const user_authentication = (payload) => {
  return {
    type: USER_AUTHENTICATION,
    payload: payload,
  };
};

export const auth_error = (error) => {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
};
export const auth_in_user = () => {
  return {
    type: AUTH_IN_USER,
  };
};

export const Authenticate_User = (user_data) => {
  return (dispatch) => {
    dispatch(auth_in_user());
    axios
      .post(
        process.env.REACT_APP_OLI_SERVER_URL + "api/authenticate/",
        user_data
      )
      .then((res) => {
        //console.log("Tokens of single user..");
        //console.log(res.data);
        const data = res.data;
        let isGood = {};
        if (res.data == "yes") isGood = {stat:"Access Granted!",isgood:true}
        else isGood = {stat:"Access Denied!",isgood:false};

        dispatch(user_authentication(isGood));
      })
      .catch((err) => {
        //console.log("Tokens of single user error..");
        //console.log(err);
        dispatch(auth_error(err));
      });
  };
};


