import React, { useState } from "react";
import classes from "./Authenticate.module.css";
import {
  Form,
  Container,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { Authenticate_User } from "../../redux/users/useAction";
import ResetPass from "./ResetPass";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function Authenticate(props) {
  const [Username, setUsername] = useState(undefined);
  const [Password, setPassword] = useState(undefined);

  const [isResettingP, setisResettingP] = useState(false);
  const [showPass, setshowPass] = useState(false);

  const login = () => {
    let data = { username: Username, password: Password };
    props.authenticate_user(data);
  };

  return (
    <div>

      <Container>
        <div
          className={`d-flex flex-column align-items-center ${classes.title_back}`}
        >
          <h1 className={classes.title}>Sunshine Of</h1>
          <h1 className={classes.title}>Heart</h1>
        </div>
        <div
          className={`d-flex flex-column justify-content-center align-items-center ${classes.authbody}`}
        >
          <div className={classes.authform}>
            {isResettingP != true ? (
              <div>
                <h2 className="text-center">Authenticate</h2>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter username"
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <Form.Label>Password</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      id="password"
                      type={showPass == true ? "text" : "password"}
                      placeholder="Enter password"
                      value={Password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <Button
                      variant="primary"
                      onClick={() => {
                        setshowPass(!showPass);
                      }}
                    >
                      {showPass == true ? (
                        <BsFillEyeFill />
                      ) : (
                        <BsFillEyeSlashFill />
                      )}
                    </Button>
                  </InputGroup>

                  <div className="text-center">
                    <Button
                      className={classes.btnlogin}
                      variant="primary"
                      onClick={login}
                    >
                      {props.userData.loading != true ? (
                        <span className="me-2">Login</span>
                      ) : (
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                    <p
                      className={classes.resetp}
                      onClick={() => {
                        setisResettingP(true);
                      }}
                    >
                      Forgot Password ?
                    </p>
                    <p className="danger">
                      {props.userData &&
                        props.userData.isUserAuthenticated.stat}
                    </p>
                  </div>
                </Form>
              </div>
            ) : (
              <ResetPass
                loginback={() => {
                  setisResettingP(false);
                }}
              />
            )}
          </div>
        </div>
        
      </Container>
      <div className={classes.starback}>
        <div className={`${classes.star} ${classes.star1}`}></div>
        <div className={`${classes.star} ${classes.star2}`}></div>
        <div className={`${classes.star} ${classes.star3}`}></div>
        <div className={`${classes.star} ${classes.star4}`}></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.UserReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    authenticate_user: (data) => dispatch(Authenticate_User(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
