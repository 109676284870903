import {
    GET_ALL_IMAGELIST,
    LOADING_IMAGELIST,
    LOADING_IMAGELIST_ERROR,
  } from "./imagelistTypes";
  
  const ImageList = {
    loading: false,
    imagelist: undefined,
    error: "",
  };
  
  const imagelistReducer = (state = ImageList, action) => {
    switch (action.type) {
      case LOADING_IMAGELIST:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_IMAGELIST:
        return {
          ...state,
          loading: false,
          imagelist: action.payload,
        };
  
      case LOADING_IMAGELIST_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default imagelistReducer;
  