import React, { useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { getAllImagelist } from "../../redux/imagelist/imagelistAction";
import classes from "./Menu.module.css";
import { BsPower } from "react-icons/bs";
import { user_authentication } from "../../redux/users/useAction";
import { PlayerInterface, Track } from "react-material-music-player";

function Menu(props) {
  useEffect(() => {
    getOli_data();
  }, []);

  const getOli_data = () => {
    console.log("Get oli data");
    props.getimagelist("oli");
  };
  const getKid_data = () => {
    console.log("Get kid data");
    props.getimagelist("kid");
  };
  const getFamily_data = () => {
    console.log("Get fam data");
    props.getimagelist("family");
  };
  const getJam_data = () => {
    console.log("Get jam, data");
    props.getimagelist("jam");
  };
  const getWyr_data = () => {
    console.log("Get wyr data");
    props.getimagelist("wyr");
  };

  const logout = () => {
    props.logout()
    PlayerInterface.play([new Track("", "", "", "", "")]);
  };
  return (
    <div>
      <Navbar  expand="lg" className={classes.body}>
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" className="me-2" />
          <Navbar.Brand href="#" className={classes.brand}>
            OS
          </Navbar.Brand>
          <Button onClick={logout} className={`d-lg-none ${classes.btnlogout}`}>  <BsPower /></Button>

          <div className="w-100">
            <Navbar.Collapse id="navbarScroll">
              <Nav className="mx-auto justify-content-center " navbarScroll>
                <Nav.Link className={classes.link} onClick={getOli_data}>
                  Olivia
                </Nav.Link>
                <Nav.Link className={classes.link} onClick={getKid_data}>
                  Kid
                </Nav.Link>
                <Nav.Link className={classes.link} onClick={getFamily_data}>
                  Family
                </Nav.Link>
                <Nav.Link className={classes.link} onClick={getJam_data}>
                  JAM
                </Nav.Link>
                <Nav.Link className={classes.link} onClick={getWyr_data}>
                  WYR
                </Nav.Link>
              </Nav>
              <Button onClick={logout} className={`d-none d-lg-block ${classes.btnlogout}`}>
                <BsPower />
              </Button>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    imagelistData: state.ImageListReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getimagelist: (folder) => dispatch(getAllImagelist(folder)),
    logout: () => dispatch(user_authentication(false)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
